import ReactGA from 'react-ga4';
import generateClientIdGa from './generate-client-id-ga';

export const gaEnvKey = 'VITE_GOOGLE_ANALYTICS_TRACKING_ID';

const ga = {
    // Initialize Google Analytics only if user consent is given
    initGoogleAnalytics() {
        const trackingId = import.meta.env[gaEnvKey];
        const cookieConsent = localStorage.getItem('cookieConsent');

        if (!trackingId) {
            console.warn('No tracking ID found for Google Analytics');
            return;
        }

        // Initialize GA only if user has accepted cookies
        if (cookieConsent === 'accepted') {
            ReactGA.initialize([
                {
                    trackingId,
                    gaOptions: {
                        anonymizeIp: true,
                        clientId: generateClientIdGa(), // Ensure the client ID is generated and passed
                    },
                },
            ]);
        } else {
            console.warn('Google Analytics not initialized due to lack of cookie consent');
        }
    },
    
    // Set user ID for Google Analytics
    setUserId(userId: string | number) {
        ReactGA.set({ userId }); // Set the user ID
    },

    // Set any other options (optional)
    setOption(key: string, value: unknown) {
        ReactGA.set({ [key]: value });
    },

    // Track a page view (ensure it's only called after consent is given)
    trackPageView(pageTitle?: string, pagePath?: string) {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (cookieConsent !== 'accepted') {
            console.warn('Cannot track page view: Cookie consent not given');
            return;
        }
        const title = pageTitle || document.title;
        const path = pagePath || window.location.pathname;
        ReactGA.send({ hitType: 'pageview', page: path, title });
    },

    // Track a custom event (Ensuring required fields like 'action' are provided)
    trackEventBuilder(categoryName: string) {
        return (options: { action: string; label?: string; value?: number }) => {
            const cookieConsent = localStorage.getItem('cookieConsent');
            if (cookieConsent !== 'accepted') {
                console.warn('Cannot track event: Cookie consent not given');
                return;
            }

            ReactGA.event({
                category: categoryName,
                action: options.action, // Required field
                label: options.label,   // Optional field
                value: options.value,   // Optional field
            });
        };
    },
};

export default ga;
