import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';
import image4 from '../assets/images/image4.png';
import step1Image from '../assets/images/step1.webp';
import step2Image from '../assets/images/step2.webp';
import step3Image from '../assets/images/step3.webp';

const ShipmentSuccess: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const labelUrl = queryParams.get('label_url');
  const trackingUrl = queryParams.get('tracking_url');

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleSignUpClick = () => {
    navigate('/auth', { state: { isLogin: false } });
  };

  // Function to open the shipping label in a new tab and print
  const handlePrint = (labelUrl: string) => {
    
    // Navigate to the ShippingLabelPrint page with the label URL as state
    navigate('/print-shipping-label', { state: { labelUrl } });
  };
  
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#f5e4c3] via-[#cfd9df] to-[#121212]">
      {/* Header */}
      <header className="w-full bg-[#b3dff5] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
    <div className="flex items-center justify-center flex-1">
      <Link to="/" className="text-3xl font-bold text-gray-900 flex items-center space-x-3 hover:text-blue-700 transition duration-300" aria-label="BooksAreReborn Home">
        <img alt="BooksAreReborn Logo" src={image4} className="h-12 w-auto animate-bounce" />
        <span className="tracking-wider">BooksAreReborn</span>
      </Link>
          <nav className="hidden lg:flex space-x-6 mx-auto">
            <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition duration-300 px-4 py-2 rounded-md text-lg font-medium">Home</Link>
            <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition duration-300 px-4 py-2 rounded-md text-lg font-medium">Your Account</Link>
            <Link to="/get-offer" className="text-gray-900 hover:text-white hover:bg-gray-700 transition duration-300 px-4 py-2 rounded-md text-lg font-medium">Cart</Link>
            <Link to="/admin-panel" className="text-gray-900 hover:text-white hover:bg-gray-700 transition duration-300 px-4 py-2 rounded-md text-lg font-medium">Admin Panel</Link>
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          {currentUser ? (
            <button onClick={handleLogout} className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow-lg transition duration-300">
              Log Out
            </button>
          ) : (
            <>
              <button onClick={() => navigate('/auth')} className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow-lg transition duration-300">
                Sign In
              </button>
              <button onClick={handleSignUpClick} className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow-lg transition duration-300">
                Sign Up
              </button>
            </>
          )}
          <button type="button" onClick={() => setMobileMenuOpen(true)} className="lg:hidden p-2 rounded-full bg-gray-900 text-white">
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </header>

      {/* Mobile Menu */}
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#57b0dc] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 shadow-xl rounded-l-2xl">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
              <img alt="BooksAreReborn Logo" src={image4} className="h-12 w-auto" />
            </Link>
            <button type="button" onClick={() => setMobileMenuOpen(false)} className="-m-2.5 rounded-md p-2.5 text-gray-700">
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link to="/" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Home</Link>
                <Link to="/your-account" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Your Account</Link>
                <Link to="/get-offer" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Cart</Link>
                <Link to="/admin-panel" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Admin Panel</Link>
              </div>
              <div className="py-6">
                {currentUser ? (
                  <button onClick={handleLogout} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log Out</button>
                ) : (
                  <>
                    <button onClick={() => navigate('/auth')} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Sign In</button>
                    <button onClick={handleSignUpClick} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Sign Up</button>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>

      {/* Main Content */}
      <main className="flex-grow pt-28 w-full max-w-3xl px-4 sm:px-6 lg:px-8 mx-auto">
        <section className="mb-10 bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-4xl font-bold text-white mb-6 p-6 rounded-lg shadow-lg bg-gradient-to-r from-green-500 to-purple-500 text-center">
            Shipment Successfully Created!
          </h1>
          <div className="text-center space-y-6">
            {labelUrl && (
              <div>
                <h2 className="text-2xl font-semibold text-green-700 mb-4">Your Shipping Label is Ready!</h2>
                <button
                  onClick={() => handlePrint(labelUrl)}
                  className="bg-green-500 text-white font-semibold py-2 px-6 rounded-lg hover:bg-green-700 transition duration-300"
                >
                  Download Your Shipping Label
                </button>
              </div>
            )}
            {trackingUrl && (
              <div>
                <h2 className="text-2xl font-semibold text-green-700 mb-4">Track Your Shipment in Real-Time</h2>
                <a href={trackingUrl} target="_blank" rel="noopener noreferrer" className="inline-block">
                  <button className="bg-green-500 text-white font-semibold py-2 px-6 rounded-lg hover:bg-green-700 transition duration-300">
                    Track Your Shipment Here
                  </button>
                </a>
              </div>
            )}
            {!labelUrl && !trackingUrl && (
              <p className="text-gray-600">Shipment details are currently unavailable. Please check back later.</p>
            )}
          </div>
        </section>

        {/* Next Steps Section */}
        <section className="mt-8 bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">What's Next?</h2>

          {/* Step 1: Print Shipping Label */}
          <div className="mb-12 flex flex-col md:flex-row items-center">
            <img src={step1Image} alt="Printing Shipping Label" className="w-full md:w-1/3 mb-4 md:mb-0 md:mr-6 rounded-lg shadow-lg" />
            <div className="md:flex-1">
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">Step 1: Print Your Shipping Label</h3>
              <p className="text-gray-700 leading-relaxed">
                Print your shipping label at full size on standard 8.5 x 11-inch paper. Cut along the edges to remove any excess white space.
              </p>
            </div>
          </div>

          {/* Step 2: Package Your Items */}
          <div className="mb-12 flex flex-col md:flex-row items-center">
            <img src={step2Image} alt="Packaging Items" className="w-full md:w-1/3 mb-4 md:mb-0 md:mr-6 rounded-lg shadow-lg" />
            <div className="md:flex-1">
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">Step 2: Package Your Items</h3>
              <p className="text-gray-700 leading-relaxed">
                Place your items securely in a sturdy box with minimal empty space. Use padding for extra protection. Affix the printed label to the top of the box.
              </p>
            </div>
          </div>

          {/* Step 3: Drop Off at Your Nearest USPS Location */}
          <div className="mb-12 flex flex-col md:flex-row items-center">
            <img src={step3Image} alt="Dropping Off Package" className="w-full md:w-1/3 mb-4 md:mb-0 md:mr-6 rounded-lg shadow-lg" />
            <div className="md:flex-1">
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">Step 3: Drop Off Your Package</h3>
              <p className="text-gray-700 leading-relaxed">
                Take your package to the nearest USPS drop-off location during operating hours to avoid delays.
              </p>
            </div>
          </div>

          {/* Additional Information */}
          <p className="text-gray-600 text-center">
            Manage all your orders and track shipments from your account dashboard. Visit the <Link to="/your-account" className="text-blue-500 hover:text-blue-700 underline transition duration-300">Your Account</Link> page to get started. You can get back to this Shipment Success page with clicking on "View Shipment Success Page" for your specific order.
          </p>
        </section>
      </main>

      <footer className="bg-gray-900 text-white py-12 mt-auto">
        <div className="container mx-auto px-8">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
            <div className="flex items-center space-x-4 flex-shrink-0">
              <img alt="BooksAreReborn Logo" src={image4} className="h-16 w-auto" />
              <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
            </div>
            <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
              <Link to="/privacy-policy" className="text-sm hover:text-gray-400 transition duration-200">Privacy Policy</Link>
              <Link to="/terms-of-use" className="text-sm hover:text-gray-400 transition duration-200">Terms of Use</Link>
              <Link to="/acceptable-use" className="text-sm hover:text-gray-400 transition duration-200">Acceptable Use</Link>
              <Link to="/contact-us" className="text-sm hover:text-gray-400 transition duration-200">Contact Us</Link>
              <Link to="/buyback-guide" className="text-sm hover:text-gray-400 transition duration-200">Buyback Guide</Link>
              <Link to="/faq" className="text-sm hover:text-gray-400 transition duration-200">FAQs</Link>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-4">
            <div className="text-center text-gray-500 text-xs">
              &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ShipmentSuccess;
