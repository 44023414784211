//C:\Users\omerb\OneDrive\Desktop\NewSeller\frontend\src\components\Notification.tsx
import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface NotificationProps {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  show: boolean;
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ message, type, show, onClose }) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setVisible(true);
    }
  }, [show]);

  if (!visible) return null;

  const getTypeClass = () => {
    switch (type) {
      case 'success':
        return 'bg-green-500';
      case 'error':
        return 'bg-red-500';
      case 'info':
        return 'bg-blue-500';
      case 'warning':
        return 'bg-yellow-500';
      default:
        return '';
    }
  };

  return (
    <div className={`fixed top-5 right-5 p-4 rounded-md shadow-lg text-white ${getTypeClass()} z-50`}>
      <div className="flex justify-between items-center">
        <span>{message}</span>
        <XMarkIcon className="h-5 w-5 cursor-pointer" onClick={onClose} />
      </div>
    </div>
  );
};

export default Notification;
