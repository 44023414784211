import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface PrivateRouteProps {
  element?: React.ReactElement; // Allow both element or children for flexibility
  children?: React.ReactNode;   // Add support for children
  isAdminRoute?: boolean;       // Boolean to determine if the route is admin-only
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, children, isAdminRoute = false }) => {
  const { currentUser } = useAuth();  // Get the current user from the AuthContext
  const location = useLocation();     // Get the current location to handle redirects

  // Check if the current user is the admin
  const isAdmin = currentUser && currentUser.email === '03omerbar@gmail.com';

  // Handle cases where an admin route is accessed but the user is not an admin
  if (isAdminRoute && !isAdmin) {
    return <Navigate to="/" state={{ from: location }} />;  // Redirect to home if not admin
  }

  // If the user is authenticated, render the element or children, otherwise, redirect to auth
  if (currentUser) {
    // Guard against both element and children being undefined
    if (element) {
      return element;  // Render the element if provided
    } else if (children) {
      return <>{children}</>;  // Render children if provided
    } else {
      console.error('PrivateRoute requires either element or children to render.');
      return null;  // Handle the case where neither element nor children is provided
    }
  } else {
    // Redirect to login if not authenticated
    return <Navigate to="/auth" state={{ from: location }} />;
  }
};

export default PrivateRoute;
