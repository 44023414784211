//C:\Users\omerb\OneDrive\Desktop\NewSeller\frontend\src\main.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import loadAnalytics from './load-analytics'; // Import Google Analytics initialization

// Initialize Google Analytics using the tracking ID from .env
const trackingId = import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID;

if (trackingId) {
  loadAnalytics(); // This assumes your loadAnalytics function uses the tracking ID from the environment
} else {
  console.error('Google Analytics tracking ID is missing');
}

// Get the root element safely
const rootElement = document.getElementById('root');

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error("Root element not found");
}
