import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ShippingLabelPrint: React.FC = () => {
  const location = useLocation();
  const { labelUrl } = location.state || {};

  // Use a ref to ensure the print is only triggered once initially
  const hasPrintedRef = useRef(false);

  // Function to handle the print once the image has loaded
  const handleImageLoad = () => {
    if (!hasPrintedRef.current) {
      hasPrintedRef.current = true;  // Set the ref to true to prevent multiple initial prints
      window.print();
    }
  };

  // Function to handle print on image click
  const handleImageClick = () => {
    window.print();  // Re-trigger print dialog when the image is clicked
  };

  if (!labelUrl) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-red-500 text-xl font-semibold">Error: Shipping label not found.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-teal-100 to-blue-200 p-6 print:bg-white">
      <h1 className="text-4xl font-bold text-center mb-6 text-purple-600 print:hidden animate-bounce">
        📦 Your Shipping Label is Ready!
      </h1>

      {/* Label container with modern rounded corners and shadow */}
      <div className="bg-[#A0522D] p-10 border-4 border-[#5C4033] rounded-none shadow-lg transform hover:scale-105 transition-transform duration-300 print:border-none print:shadow-none">
        {/* Instruction to click the label to print */}
        <p className="text-xl font-semibold text-white text-center mb-6 print:hidden bg-gradient-to-r from-teal-400 to-blue-500 px-6 py-4 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
  📄 Click or tap the shipping label below to print it instantly.
</p>



        {/* Wait for the image to load before calling print */}
        <img 
          src={labelUrl} 
          alt="Shipping Label" 
          className="mx-auto block max-w-[4in] max-h-[6in] w-full h-auto rounded-md cursor-pointer hover:shadow-md transition-shadow duration-300"
          onLoad={handleImageLoad}  // Trigger print when image is loaded initially
          onClick={handleImageClick}  // Re-trigger print when image is clicked
        />
      </div>
      
      {/* Guidelines for the users */}
      <div className="mt-10 space-y-6 text-center print:hidden">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold text-teal-700">📦 Step 1: Pack Your Box</h2>
          <p className="text-lg text-gray-800 mt-3">Use a sturdy box and pack your items carefully. Fill any empty spaces with padding like bubble wrap or paper to protect your items during shipping. Seal the box tightly with packing tape.</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold text-green-600">📝 Step 2: Attach the Label</h2>
          <p className="text-lg text-gray-800 mt-3">Place the label on the top or largest side of the box. Make sure the barcode is clear and not bent. Secure it with clear tape without covering the barcode.</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold text-yellow-600">🚚 Step 3: Drop It Off</h2>
          <p className="text-lg text-gray-800 mt-3">Take your package to the nearest USPS location. You can find the closest drop-off point using the USPS website or app. Make sure to get a receipt for tracking your shipment!</p>
        </div>
      </div>

      {/* Print-specific styles to ensure the label fits within 4x6 dimensions */}
      <style>
        {`
          @media print {
            body {
              margin: 0;
              padding: 0;
              width: 4in;
              height: 6in;
            }
            img {
              width: 100%;
              height: auto;
              max-width: 4in;
              max-height: 6in;
            }
            @page {
              size: 4in 6in; /* Set the print size to 4x6 */
              margin: 0; /* Remove margins */
            }
          }
        `}
      </style>
    </div>
  );
};

export default ShippingLabelPrint;
