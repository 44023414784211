import React, { useState, useEffect } from 'react';
import cookiesImage from '../assets/images/cookies.webp'; // Import the image file you have
import ga from '../ga-init'; // Import Google Analytics

const CookiesConsent: React.FC = () => {
  const [showConsent, setShowConsent] = useState(false);
  const [isMobile, setIsMobile] = useState(false); // New state to check if it's a mobile device

  // Check if user is on a mobile device
  useEffect(() => {
    const isMobileDevice = window.innerWidth <= 768;
    setIsMobile(isMobileDevice);
  }, []);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted') {
      ga.initGoogleAnalytics(); // Initialize GA only if consent is given
    } else if (!consent) {
      // Delay showing the consent banner for better UX (especially in mobile browsers)
      setTimeout(() => {
        setShowConsent(true);
      }, 2000); // Delay by 2 seconds
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowConsent(false);
    ga.initGoogleAnalytics(); // Initialize GA when accepted
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setShowConsent(false);
  };

  if (!showConsent) return null; // Don't render the banner if consent is already given/rejected

  return (
    <div className={`fixed bottom-0 left-0 right-0 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white p-4 z-50 shadow-lg flex justify-between items-center space-x-4 rounded-t-lg ${isMobile ? 'flex-col space-y-4' : 'flex-row space-x-6'}`}>
      {/* Image Section */}
      <div className="flex items-center justify-center">
        <img
          src={cookiesImage}
          alt="Cookies Character"
          className="w-16 h-16 object-contain" // Reduced size for mobile friendliness
        />
      </div>

      {/* Text and Buttons Section */}
      <div className="flex justify-between items-center w-full">
        <div className="max-w-md text-center md:text-left">
          <h3 className="text-lg font-bold mb-2">We Value Your Privacy</h3>
          <p className="text-sm">
            We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. By clicking "Accept", you consent to the use of cookies, including analytics.
          </p>
        </div>
        <div className={`flex ${isMobile ? 'flex-col space-y-2' : 'space-x-4'}`}>
          <button
            onClick={handleAccept}
            className="bg-green-500 hover:bg-green-600 text-white px-6 py-3 rounded-full shadow-lg transition-transform transform hover:scale-105"
          >
            Accept All
          </button>
          <button
            onClick={handleReject}
            className="text-gray-300 hover:text-gray-100 border border-gray-400 px-6 py-3 rounded-full transition"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesConsent;