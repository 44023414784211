import { v4 as uuidv4 } from 'uuid';

export const gaClientStorageKey = 'ga-gtag-client-id';
export const cookieConsentKey = 'cookieConsent';

const generateClientIdGa = (): string | null => {
    const cookieConsent = localStorage.getItem(cookieConsentKey);

    // Only generate and store client ID if the user has accepted cookies
    if (cookieConsent === 'accepted') {
        let clientId = localStorage.getItem(gaClientStorageKey);

        if (!clientId) {
            clientId = uuidv4();
            localStorage.setItem(gaClientStorageKey, clientId);
        }

        return clientId;
    } else {
        console.warn('Client ID generation skipped due to lack of cookie consent');
        return null; // Return null if no consent was given
    }
};

export default generateClientIdGa;
