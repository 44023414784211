import { Dialog, DialogPanel } from '@headlessui/react';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel'
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-gray-800 bg-opacity-75">
      <DialogPanel className="bg-white rounded-lg p-6 shadow-xl">
        <h2 className="text-xl font-bold mb-4">{message}</h2>
        <div className="flex space-x-4 justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
          >
            {cancelButtonText}
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
          >
            {confirmButtonText}
          </button>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default ConfirmationModal;
