import { useEffect } from 'react';
import { getToken } from 'firebase/messaging';
import { messaging, db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

// Function to save the device token in Firestore
const saveDeviceToken = async (token: string, userId: string) => {
  try {
    console.log(`Saving token ${token} for user ${userId}`);
    await setDoc(doc(db, 'users', userId), { deviceToken: token }, { merge: true });
    console.log('Token saved successfully');
  } catch (error) {
    console.error("Error saving device token:", error);
  }
};

const useDeviceToken = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        // Check if messaging is defined
        if (!messaging) {
          console.warn('Firebase Messaging is not supported in this browser.');
          return;
        }

        console.log('Registering service worker');
        const swRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        console.log('Service worker registered');

        // Get the device token using VAPID key
        const token = await getToken(messaging, {
          vapidKey: 'BKjq15s-AwG0-lnpxqjGqAqT3BmeAqSFx78RChvfAT13uo1ZCTNtL9ut2Rx6-ZMNVWKx2y0uRpS9y-lQZC7tIpE', // Replace with your actual VAPID key
          serviceWorkerRegistration: swRegistration
        });

        if (token) {
          console.log('Device token:', token);
          if (currentUser) {
            saveDeviceToken(token, currentUser.uid);
          }
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      } catch (error) {
        console.error('An error occurred while retrieving token.', error);
      }
    };

    if (currentUser) {
      fetchToken();
    }
  }, [currentUser]);
};

export default useDeviceToken;
