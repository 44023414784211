import ga from './ga-init';

const loadAnalytics = () => {
  const trackingId = import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID;

  // Check if the user has accepted cookies
  const cookieConsent = localStorage.getItem('cookieConsent');

  if (!cookieConsent) {
    console.log('No cookie consent decision made yet. Google Analytics will not be initialized.');
    return; // Do not initialize Google Analytics if no consent decision has been made
  }

  if (cookieConsent !== 'accepted') {
    console.log('User has rejected cookies. Google Analytics will not be initialized.');
    return; // Do not initialize Google Analytics if cookies are rejected
  }

  if (trackingId) {
    console.log(`Initializing Google Analytics with tracking ID: ${trackingId}`);
    ga.initGoogleAnalytics(); // Initialize Google Analytics if consent is accepted
  } else {
    console.error('Google Analytics tracking ID is missing in the environment variables.');
  }
};

export default loadAnalytics;
