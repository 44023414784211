import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ref, off } from 'firebase/database';
import { rtdb } from '../firebase';
import { Link } from 'react-router-dom';
import image4 from '../assets/images/image4.png'; // Import the logo image

interface Item {
  itemName: string;
  isbn: string;
}

interface Order {
  shipment_id: string;
  tracking_code: string;
  tracking_url: string;
  status: string;
  estimated_delivery_date: string | null;
  label_url: string;
  email: string;
  order_id: string;
  total_values: number | undefined;
  total_quantities: number | undefined;
  dateCreated: string; // Add dateCreated field
  items?: Item[];
}

const AdminPanel: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [sortConfig, setSortConfig] = useState<{ key: keyof Order; direction: 'asc' | 'desc' } | null>(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://resellerapp-backend.azurewebsites.net/get_all_orders');
        const data = response.data.orders;

        // Convert snake_case 'date_created' to camelCase 'dateCreated'
        const ordersWithCamelCase: Order[] = data.map((order: any) =>
          Object.assign({}, order, {
            dateCreated: order.date_created // Map date_created to dateCreated
          })
        );
        
        if (Array.isArray(ordersWithCamelCase)) {
          setOrders(ordersWithCamelCase);
        } else {
          console.error('Unexpected data format:', ordersWithCamelCase);
          setError('Failed to fetch orders. Please check your permissions.');
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError('Failed to fetch orders. Please check your permissions.');
      }
    };

    fetchOrders();

    return () => {
      const ordersRef = ref(rtdb, 'shipments');
      off(ordersRef);
    };
  }, []);

  const handleSort = (key: keyof Order) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedOrders = React.useMemo(() => {
    let sortableOrders = orders.slice(); // Shallow copy the orders array
    if (sortConfig !== null) {
      sortableOrders.sort((a, b) => {
        if (a[sortConfig.key]! < b[sortConfig.key]!) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key]! > b[sortConfig.key]!) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableOrders;
  }, [orders, sortConfig]);

  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#f5e4c3] via-[#cfd9df] to-[#121212]">
      {/* Header */}
      <header className="w-full bg-[#f5deb3] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
        <div className="flex items-center justify-center flex-1">
          <Link to="/" className="text-2xl font-bold text-gray-900 flex items-center space-x-2">
            <img
              alt="BooksAreReborn Logo"
              src={image4}
              className="h-12 w-auto" // Adjusted logo size for the header
            />
            <span>BooksAreReborn</span>
          </Link>
          <nav className="hidden lg:flex space-x-4 mx-auto">
            <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Home
            </Link>
            <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Profile Details
            </Link>
            <Link to="/get-offer" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Cart
            </Link>
            <Link to="/admin-panel" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Admin Panel
            </Link>
          </nav>
        </div>
        <button className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition">
          Log Out
        </button>
      </header>

      {/* Main Content */}
      <main className="pt-24 w-full max-w-7xl mx-auto px-4 py-16">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Admin Panel</h1>
          <div className="relative">
            <input type="text" id="table-search-users" className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search for orders" />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
              </svg>
            </div>
          </div>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                {[
                  { label: 'Order ID', key: 'order_id' },
                  { label: 'Tracking Number', key: 'tracking_code' },
                  { label: 'Status', key: 'status' },
                  { label: 'Estimated Delivery Date', key: 'estimated_delivery_date' },
                  { label: 'Label', key: 'label_url' },
                  { label: 'Email', key: 'email' },
                  { label: 'Total Values', key: 'total_values' },
                  { label: 'Total Quantities', key: 'total_quantities' },
                  { label: 'Date Created', key: 'dateCreated' }, // New column for dateCreated
                  { label: 'Items', key: '' },
                ].map(({ label, key }) => (
                  <th
                    key={key || label}
                    className="px-6 py-3 cursor-pointer hover:bg-gray-100"
                    onClick={() => key && handleSort(key as keyof Order)}
                  >
                    {label} {sortConfig && sortConfig.key === key ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedOrders.length > 0 ? (
                sortedOrders.map((order, index) => (
                  <tr key={`${order.order_id}-${index}`} className="bg-white border-b hover:bg-gray-50">
                    <td className="px-6 py-4 truncate">{order.order_id}</td>
                    <td className="px-6 py-4 truncate">
                      <a 
                        href={order.tracking_url} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-blue-600 hover:underline"
                      >
                        {order.tracking_code}
                      </a>
                    </td>
                    <td className="px-6 py-4 truncate">{order.status}</td>
                    <td className="px-6 py-4 truncate">
                      {order.estimated_delivery_date || 'N/A'}
                    </td>
                    <td className="px-6 py-4 truncate">
                      <a href={order.label_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">View Label</a>
                    </td>
                    <td className="px-6 py-4 truncate">{order.email}</td>
                    <td className="px-6 py-4 truncate">${(order.total_values || 0).toFixed(2)}</td>
                    <td className="px-6 py-4 truncate">{order.total_quantities || 0}</td>
                    <td className="px-6 py-4 truncate">
                      {order.dateCreated ? formatDate(order.dateCreated) : 'N/A'} {/* Display dateCreated */}
                    </td>
                    <td className="px-6 py-4 truncate">
                      <ul className="list-disc pl-4">
                        {order.items && order.items.map((item, idx) => (
                          <li key={`${item.isbn}-${idx}`} className="text-gray-700 truncate">{item.itemName} (ISBN: {item.isbn})</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10} className="px-6 py-4 text-center">No orders found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </main>
      <br />
      <footer className="bg-gray-900 text-white py-10">
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
            <div className="flex items-center space-x-4 flex-shrink-0">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-14 w-auto"
              />
              <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
            </div>
            <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
              <Link
                to="/privacy-policy"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-of-use"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Terms of Use
              </Link>
              <Link
                to="/acceptable-use"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Acceptable Use
              </Link>
              <Link
                to="/contact-us"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Contact Us
              </Link>
              <Link
                to="/buyback-guide"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Buyback Guide
              </Link>
              <Link
                to="/faq"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                FAQs
              </Link>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-4">
            <div className="text-center text-gray-500 text-xs">
              &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AdminPanel;